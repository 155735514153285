<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title=" معلومات الفرد الشخصية " v-if="person.personal_info">
          <title></title>
          <b-row >
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاسم</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.first_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الاب</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.father_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> الام</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.mother_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> النسبة</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.last_name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" v-if="person.personal_info.gender">
              <b-form-group>
                <label> الجنس</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.gender.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.family_status">
              <b-form-group>
                <label> طبيعة العائلة </label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.family_status.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group>
                <label> العمر</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.age }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.nationality">
              <b-form-group label-for="nationality">
                <label>الجنسية</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.nationality.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.current_work">
              <b-form-group label-for="current_work">
                <label> العمل الحالي</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.current_work.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.last_work">
              <b-form-group label-for="last_work">
                <label> العمل السابق</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.last_work.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.martial_status">
              <b-form-group label-for="martial_status">
                <label> الحالة الاجتماعية</label>

                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.martial_status.name }}
                </h5>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3">
              <b-form-group label-for="birth_place">
                <label> مكان الولادة</label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.birth_place }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="note">
                <label> الملاحظات </label>

                <h5 class="text-capitalize mb-75">
                    {{person.personal_info.notes}}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.educational_attainment">
              <b-form-group label-for="educational_attainment">
                <label> التحصيل العلمي </label>

                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.educational_attainment.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.educational_status">
              <b-form-group label-for="number">
                <label> حالة التعليم </label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.educational_status.name }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.family_members_count">
              <b-form-group label-for="number">
                <label>  عدد افراد  الاسرة </label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.family_members_count }}
                </h5>
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3" v-if="person.personal_info.family_children_count">
              <b-form-group label-for="number">
                <label>  عدد الاطفال في الاسرة </label>
                <h5 class="text-capitalize mb-75">
                  {{ person.personal_info.family_children_count }}
                </h5>
              </b-form-group>
            </b-col>
          </b-row>
          

          <b-row>
            <b-col md="6" xl="4">
              <b-form-group label-for="birth_date">
                <label> تاريخ الميلاد</label>
                <b-form-datepicker
                  id="example-datepicker5"
                  v-model="person.birth_date"
                  class="mb-1"
                  readonly
                />
                <!-- <b-form-input v-model="person.dateOfBirth" placeholder="" /> -->
              </b-form-group>
            </b-col>

          </b-row>
          <b-card-code
          v-if="person.personal_info"
          title=" مكان الأقامة الحالي"
        >
          <title />

          <b-row>
            <!-- credit card -->
            <b-col
              md="6"
              xl="3"
              v-if="person.personal_info.current_address.country.name "
            >

              <label> البلد</label>
              <h5 class="text-capitalize mb-75">

                {{ person.personal_info.current_address.country.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="person.personal_info.current_address.governorate.name "
            >
              <label> المحافظة</label>
              <h5 class="text-capitalize mb-75">
                {{ person.personal_info.current_address.governorate.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="person.personal_info.current_address.city.name "
            >

              <label> المنطقة</label>
              <h5 class="text-capitalize mb-75">
                {{ person.personal_info.current_address.city.name }}
              </h5>
            </b-col>
            <b-col
              md="6"
              xl="3"
              v-if="person.personal_info.current_address.town.name "
            >

              <label> الناحية</label>
              <h5 class="text-capitalize mb-75">
                {{ person.personal_info.current_address.town.name }}
              </h5>
            </b-col>

            <b-col
              md="6"
              xl="3"
              v-if="person.personal_info.current_address.residence_type.name "
            >

              <label> نوع السكن</label>
              <h5 class="text-capitalize mb-75">
                {{ person.personal_info.current_address.residence_type.name }}
              </h5>
            </b-col>
            <b-col
              v-if=" person.personal_info.current_address.residence_notes"
              md="6"
              xl="3"
            >

              <label> تفاصيل العنوان</label>
              <h5 class="text-capitalize mb-75">
                {{ person.personal_info.current_address.residence_notes }}
              </h5>
            </b-col>

          </b-row>
        </b-card-code>

          <b-row v-if="person.weaknesses">
            <b-col xl="2"> <label> نقاط الضعف</label></b-col>
            <b-col v-for="(weak, index) in person.weaknesses" :key="index" xl="3">
              <b-form-group label-for="time">
                <h5 class="text-capitalize mb-75">
                  {{ weak.name }}
                </h5></b-form-group
              >
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";

import "cleave.js/dist/addons/cleave-phone.us";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: {
    BForm,
    BCardCode,

    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,

    BRow,

    BCol,
  },
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // person:{
      //   gender:{
      //     name:""
      //   }
      // }
    };
  },
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-size: 12px;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
